import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Link, Grid } from '@material-ui/core'

function KlarnaInfo({ klarnaSettlement }) {
  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs={2}>
        <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg" alt="Klarna" />
      </Grid>
      <Grid item xs={3}>
        <Link href="https://www.klarna.com/us/klarna-app/" target="_blank">
          <Typography variant="caption">Download the Klarna App</Typography>
        </Link>
      </Grid>
    </Grid>
  )
}

KlarnaInfo.propTypes = {
  klarnaSettlement: PropTypes.object.isRequired,
}

export default KlarnaInfo
